import { useEffect, useState } from "react";
import { useUser } from "$/state/user";
import { OrderByArg, Session, SessionState, useListSessionsQuery } from "$/graphql/types.generated";
import { addMinutes, startOfHour } from "date-fns";
import { CoacheeSessionCard } from "$/components/SessionCard/CoacheeSessionCard";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import { Modal } from "$/components/Modal";
import { Button } from "$/components/Button";
import { ArrowRight } from "lucide-react";
import { CoachSessionCard } from "$/components/SessionCard/CoachSessionCard";
import { CoachToolCard } from "./components/CoachToolCard";
import { CoachStats } from "./components/coachStats";

export const CoachDashboard = () => {
  const user = useUser();
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  useEffect(() => {
    const seenWelcomeModal = localStorage.getItem("seenCoachDashboardWelcomeModal");
    if (seenWelcomeModal !== "3") {
      setShowWelcomeModal(true);
    }
  }, []);

  const { data: futureCoachSession, isLoading } = useListSessionsQuery({
    where: {
      coachId: { equals: user.currentUser?.coach?.id },
      startsAt: { gte: addMinutes(startOfHour(new Date()), 30) },
      state: { in: [SessionState.Confirmed, SessionState.ConfirmedCoach] },
    },
    orderBy: [{ startsAt: OrderByArg.Asc }],
    take: 1,
  });

  const { data: futureCoacheeSession } = useListSessionsQuery({
    where: {
      teamMemberId: { equals: user.currentUser?.teamMember?.id },
      startsAt: { gte: addMinutes(startOfHour(new Date()), 30) },
      state: { in: [SessionState.Confirmed, SessionState.ConfirmedCoach] },
    },
    orderBy: [{ startsAt: OrderByArg.Asc }],
    take: 1,
  });

  const nextCoachSession = futureCoachSession?.listSessions[0] || null;
  const nextCoacheeSession = futureCoacheeSession?.listSessions[0] || null;

  return (
    <div className="max-w-[1240px] mx-auto">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="flex flex-col w-full">
            <div className="w-full flex flex-col mt-4 computer:flex-row">
              <div className="w-full px-4 flex flex-col computer:m-0 computer:w-1/2 computer:px-0 ">
                <h2 className="w-full text-center text-3xl mb-2">Your next session as a coach</h2>
                <div className="relative computer:w-[1/2] max-w-[600px] mx-auto computer:mx-4">
                  {nextCoachSession ? (
                    <CoachSessionCard session={nextCoachSession as Session} timescale="future" />
                  ) : (
                    <div className="w-full computer:max-w-1/2 flex flex-col m-auto h-full">
                      <div className="w-full flex flex-col bg-peach rounded-t-xl px-1">
                        <div className="w-full flex justify-center">
                          <img
                            className="w-1/4 mb-[-6px]"
                            src="/images/Book_a_session.svg"
                            alt="coaching"
                          />
                        </div>
                      </div>
                      <div className="w-full flex flex-col justify-between bg-white rounded-b-xl px-4 h-full">
                        <div className="w-full m-2 flex flex-col justify-center">
                          <h3 className="text-md m-1">
                            You currently don&apos;t have any future sessions as coach.
                          </h3>
                          <h4 className="text-sm m-1">
                            To maximise your potential for bookings, ensure your Google Calendar
                            availability is updated for at least the next six weeks.
                          </h4>
                          <h4 className="text-sm m-1 mb-0">
                            See below for tips on opening more availability to boost bookings while
                            still capping your weekly sessions to avoid overload
                          </h4>
                        </div>
                        <div className="flex justify-end pb-4">
                          <a
                            href={
                              "https://www.notion.so/The-Coach-Handbook-73a5e138483447acb937ad00dadbe3c5?p=307af46393774afd92967d88de92c5e9&pm=c"
                            }
                          >
                            <Button primary rightIcon={<ArrowRight size={18} />}>
                              Availability explained
                            </Button>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full px-4 flex flex-col mt-4 computer:m-0 computer:w-1/2 computer:px-0">
                <h2 className="w-full text-center text-3xl mb-2">Your next session as a coachee</h2>
                <div className="relative computer:w-[1/2] max-w-[600px] mx-auto computer:mx-4">
                  {nextCoacheeSession ? (
                    <CoacheeSessionCard
                      session={nextCoacheeSession as Session}
                      timescale="future"
                    />
                  ) : (
                    <div className="w-full max-w-[600px] flex flex-col justify-center m-auto h-full">
                      <div className="w-full flex flex-col bg-lightBlue rounded-t-xl px-1">
                        <div className="w-full flex justify-center">
                          <img className="w-1/4 mb-[-6px]" src="/images/Huddle.svg" alt="group" />
                        </div>
                      </div>
                      <div className="w-full flex flex-col justify-between bg-white rounded-b-xl px-4 h-full">
                        <div className="w-full m-2 flex flex-col justify-center">
                          <h3 className="text-md m-1">
                            You currently don&apos;t have any future sessions as coachee.
                          </h3>
                          <h4 className="text-md m-1">
                            Did you know you also get access to More Happi coaching?
                          </h4>
                          <h4 className="text-sm m-1 pb-2">
                            You can use coaching to develop your skills as a coach, learn styles and
                            techniques from others, to help map out building your personal brand or
                            anything else!
                          </h4>
                        </div>
                        <div className="flex justify-end pb-4">
                          <a href={"/book-coaching"}>
                            <Button tertiarySolid rightIcon={<ArrowRight size={18} />}>
                              Book coaching
                            </Button>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex mt-4 justify-center px-4">
              <a href="/sessions" className="cursor-pointer text-balance hover:no-underline">
                <Button large tertiary rightIcon={<ArrowRight size={18} />}>
                  View my previous and upcoming sessions
                </Button>
              </a>
            </div>
          </div>
          <div className="flex flex-col mt-2">
            <h2 className="text-3xl pl-4">Coach tools</h2>
            <div className="w-full flex px-4 m-auto pb-4 gap-6 overflow-x-scroll computer:scrollbar-hide computer:justify-center computer:px-0">
              <CoachToolCard
                heading={"Coach Handbook"}
                subheading={"Have a question? This is your guide for coaching at More Happi"}
                imageString={"/images/Books.svg"}
                colour={"orange"}
                link={"https://www.notion.so/The-Coach-Handbook-73a5e138483447acb937ad00dadbe3c5"}
              />
              <CoachToolCard
                heading={"Coach Rebooking"}
                subheading={
                  "Make sure you are up to speed for your next call with this new feature"
                }
                imageString={"/images/Listening.svg"}
                colour={"lightGreen"}
                link={
                  "https://www.notion.so/Coach-Rebooking-New-Feature-114c2746959d8094abfccb97c8378a01"
                }
              />
              <CoachToolCard
                heading={"Update Availability"}
                subheading={"It's important to keep your availability up to date, learn how"}
                imageString={"/images/Painting.svg"}
                colour={"peach"}
                link={
                  "https://www.notion.so/The-Coach-Handbook-73a5e138483447acb937ad00dadbe3c5?p=307af46393774afd92967d88de92c5e9&pm=c"
                }
              />
              <CoachToolCard
                heading={"Supervision Sessions"}
                subheading={"Book sessions to discuss your growth & challenges as a coach"}
                buttonText="Book now"
                imageString={"/images/Support_Guides.svg"}
                colour={"pink"}
                link={"https://www.notion.so/Supervision-f1bf7975ae124e9ea1d0f713ec0f4f56"}
              />
              <CoachToolCard
                heading={"Happi Hour"}
                subheading={"Monthly training. Book your next session or watch past recordings"}
                buttonText="Book now"
                imageString={"/images/Tools.svg"}
                colour={"lightPurple"}
                link={"https://www.notion.so/Happi-Hour-03a60e94338e483aa8c6ac0576d99add"}
              />
            </div>
          </div>
          <CoachStats />

          <div className="w-[95%] computer:w-3/4 flex flex-col bg-white rounded-xl m-auto mb-4 p-2 px-6 border-solid border-2 border-orange items-center text-center">
            <h4 className="text-lg">
              We&apos;re continuously working to make this dashboard as useful as possible for
              coaches.
            </h4>
            <h4 className="text-lg">
              If you have any ideas for improvements, reach out to us at{" "}
              <a href={"mailto:hey@morehappi.com"} target="_blank" rel="noreferrer">
                hey@morehappi.com
              </a>
              .
            </h4>
            <h4 className="text-lg">
              We value your feedback and are committed to enhancing your coaching experience.
            </h4>
          </div>
        </div>
      )}
      {showWelcomeModal && (
        <Modal
          size="lg"
          onClose={() => {
            setShowWelcomeModal(false);
            const seenWelcomeModal = localStorage.getItem("seenCoachDashboardWelcomeModal");
            if (seenWelcomeModal === "2") {
              localStorage.setItem("seenCoachDashboardWelcomeModal", "3");
            } else if (seenWelcomeModal === "1") {
              localStorage.setItem("seenCoachDashboardWelcomeModal", "2");
            } else {
              localStorage.setItem("seenCoachDashboardWelcomeModal", "1");
            }
          }}
        >
          <div className="flex flex-col m-auto p-4 items-center text-center gap-4">
            <h3 className="text-3xl">
              Welcome to your <i>new</i> Coach Dashboard!
            </h3>
            <h4 className="text-lg">
              Before every coaching session, please remember to log in here. This dashboard contains
              everything you need to coach effectively with More Happi.
            </h4>
            <h4 className="text-lg">
              From here you&apos;ll be able to:
              <ul className="flex flex-col pt-4 text-start gap-y-2">
                <li>See important details about your upcoming session</li>
                <li>Review your latest testimonials and stats</li>
                <li>Be able to book the next session for your coachee</li>
              </ul>
            </h4>
            <h4 className="text-lg m-0">
              We&apos;re continuously working to make this dashboard as useful as possible for
              coaches.
            </h4>
            <h4 className="text-lg m-0">
              If you have any ideas for improvements, reach out to us at{" "}
              <a href={"mailto:hey@morehappi.com"} target="_blank" rel="noreferrer">
                hey@morehappi.com
              </a>
              .
            </h4>
            <h4 className="text-lg m-0">
              We value your feedback and are committed to enhancing your coaching experience.
            </h4>
          </div>
        </Modal>
      )}
    </div>
  );
};
