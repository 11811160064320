import { useUser } from "$/state/user";
import {
  OrderByArg,
  SessionState,
  useGetCoachQuery,
  useListCoachSessionReviewsQuery,
  useListSessionsQuery,
} from "$/graphql/types.generated";
import { addMinutes, startOfHour } from "date-fns";
import { app } from "$/configs/app.config";
import { Building2, Clock, Star, User, ChartNoAxesCombined, Mic2, CheckCircle } from "lucide-react";
import { getReviews } from "$/components/CoachCard/CoachTestimonials";
import { Button } from "$/components/Button";
import { DateTime } from "luxon";
import { useIsMobile } from "$/hooks";

export const CoachStats = () => {
  const user = useUser();
  const isMobile = useIsMobile();

  const getCoachQuery = useGetCoachQuery({
    where: { id: user.currentUser.coach.id },
  });

  const coach = getCoachQuery?.data?.getCoach;

  const { data: allCoachSessions } = useListSessionsQuery({
    where: {
      coachId: { equals: user.currentUser?.coach?.id },
      state: { in: [SessionState.Confirmed, SessionState.ConfirmedCoach, SessionState.Completed] },
      startsAt: { lte: addMinutes(startOfHour(new Date()), 30) },
    },
    orderBy: [{ startsAt: OrderByArg.Asc }],
  });

  const { data: coachSessionReviews } = useListCoachSessionReviewsQuery({
    coachId: user?.currentUser.coach.id,
  });

  const { reviews, meanReview, filtered, totalCount, byDate, byStars } = getReviews(
    coach?.reviews,
    coachSessionReviews?.listSessionReviews,
  );

  const allSessionsAmount = allCoachSessions?.listSessions.length || 0;

  const uniqueCompaniesCount = new Set(
    allCoachSessions?.listSessions.map((session) => session.teamMember.company.name),
  ).size;

  const uniqueUsersCount = new Set(
    allCoachSessions?.listSessions.map((session) => session.teamMember.id),
  ).size;

  return (
    <>
      <div className="w-full flex flex-col computer:flex-row m-auto mt-4 pb-4 gap-6 items-stretch">
        <div className="w-[95%] computer:w-1/2 flex flex-col flex-grow bg-white rounded-xl m-auto computer:ml-4 pb-5 items-center">
          <div className="flex flex-col items-center justify-center pt-6">
            <img
              src={decodeURIComponent(
                `${app.CLOUDFRONT.USER_ASSETS}/${user.currentUser.coach.id}/thumbnail.jpg`,
              )}
              alt="Profile"
              className="object-cover aspect-w-1 aspect-h-1 w-[100px] h-[100px] rounded-full"
            />
          </div>
          <h2 className="mt-2 m-0 text-center text-2xl md:text-3xl">
            {user.currentUser.firstName}, you&apos;re amazing.
          </h2>
          <h2 className="mb-4 text-center text-2xl md:text-3xl">During your time at More Happi:</h2>
          <div className="w-[95%] computer:w-3/4 flex justify-center m-3 p-2 gap-x-2 border-2 border-solid border-green-700 rounded-full">
            <CheckCircle />
            <h2 className="m-0 text-base md:text-xl">
              So far, you have completed {allSessionsAmount} sessions
            </h2>
          </div>
          <div className="w-[95%] computer:w-3/4 flex justify-center m-3 p-2 gap-x-2 border-2 border-solid border-green-700 rounded-full">
            <Clock />
            <h2 className="m-0 text-base md:text-xl">
              That equals {allSessionsAmount * 0.75} coaching hours
            </h2>
          </div>
          <div className="w-[95%] computer:w-3/4 flex justify-center m-3 p-2 gap-x-2 border-2 border-solid border-green-700 rounded-full">
            <User />
            <h2 className="m-0 text-base md:text-xl">
              You&apos;ve coached {uniqueUsersCount} unique people
            </h2>
          </div>
          <div className="w-[95%] computer:w-3/4 flex justify-center m-3 p-2 gap-x-2 border-2 border-solid border-green-700 rounded-full">
            <Building2 />
            <h2 className="m-0 text-base md:text-xl">
              Your coachees are from {uniqueCompaniesCount} different companies
            </h2>
          </div>
        </div>
        <div className="w-[95%] computer:w-1/2 flex flex-col flex-grow bg-white rounded-xl m-auto computer:m-0 computer:mr-4 pb-5 items-center">
          <h2 className="mt-6 mb-3 px-4 text-center text-2xl md:text-3xl">
            On average, your coachees rate you:
          </h2>
          <div className="relative">
            <Star stroke="#F5C900" fill="#F5C900" size={isMobile ? 200 : 250} />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
              <h2 className="text-5xl font-bold m-0">{meanReview}</h2>
              <h2 className="text-xl m-0">/5</h2>
            </div>
          </div>
          <div className="w-3/4 flex flex-col mt-auto gap-y-4">
            <h2 className="text-xl m-0">From {totalCount} reviews:</h2>
            <div className="flex justify-between">
              <h2 className="text-xl m-0 pr-3">5/5</h2>
              <div className={`m-2 rounded-full w-full bg-yellow`} />
              <h2 className="text-xl m-0 pl-3">{byStars.five}</h2>
            </div>
            <div className="flex w-full">
              <h2 className="text-xl m-0 pr-3">4/5</h2>
              <div
                className="rounded-full m-2 pl-3 bg-yellow"
                style={{ width: `${(byStars.four / byStars.five) * 100}%` }}
              />
              <h2 className="text-xl m-0 ml-auto">{byStars.four}</h2>
            </div>
            <div className="flex w-full">
              <h2 className="text-xl m-0 ml-[-16px]">{"≤ "}3/5</h2>
              <div
                className="rounded-full m-2 ml-5 pl-3 bg-yellow"
                style={{ width: `${(byStars.threeOrLess / byStars.five) * 100}%` }}
              />
              <h2 className="text-xl m-0 ml-auto">{byStars.threeOrLess}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <a
          href={`https://morehappi.retool.com/embedded/public/fd916890-9043-4fed-9d91-1e833a37642e?coid=${user?.currentUser?.coach?.id}`}
          target="_blank"
          rel="noreferrer"
          className="cursor-pointer hover:no-underline"
        >
          <Button large tertiarySolid leftIcon={<ChartNoAxesCombined size={18} />}>
            View my detailed stats
          </Button>
        </a>
      </div>

      <div className="w-full bg-yellow rounded-xl">
        <div className="flex flex-col justify-center gap-4 text-center py-6 my-4">
          <h3 className="text-3xl">Your recent testimonials</h3>
          <div className="flex flex-wrap justify-around gap-6">
            {byDate.slice(0, 6)?.map((review) => (
              <div className="w-[95%] computer:w-1/4 flex flex-col m-auto bg-peach border-solid border-2 border-midnight rounded-xl">
                <p className="m-0 font-semibold">
                  {review?.company?.name
                    ? `${review?.company?.name.replace(/ *\([^)]*\) */g, "")}`
                    : "Anonymous"}
                </p>
                <p className="m-0 font-semibold">
                  {DateTime.fromISO(review?.createdAt).toFormat("MMMM yyyy")}
                </p>
                <div className="w-full flex flex-col m-auto bg-white rounded-b-xl border-solid border-0 border-t-2 border-midnight">
                  <p
                    className="m-4 overflow-hidden text-ellipsis"
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 10,
                      WebkitBoxOrient: "vertical",
                      lineClamp: 10,
                      whiteSpace: "normal",
                    }}
                  >
                    &quot;{review.body}&quot;
                  </p>
                </div>
              </div>
            ))}
          </div>
          <a
            href={`https://morehappi.retool.com/embedded/public/fd916890-9043-4fed-9d91-1e833a37642e?coid=${user?.currentUser?.coach?.id}`}
            target="_blank"
            rel="noreferrer"
            className="cursor-pointer hover:no-underline"
          >
            <Button large tertiarySolid leftIcon={<Mic2 size={18} />}>
              See all testimonials and feedback
            </Button>
          </a>
        </div>
      </div>
    </>
  );
};
